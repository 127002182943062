import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { logout } from '@a1-ict/core/authSlice';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    window.location.href = '/';
  }, []);
  return null;
};
export default Logout;
